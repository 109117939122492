import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import Box from '../Box'

const animatedStyle = css`
  &:after {
    background: linear-gradient(225deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    animation: phAnimation 1.2s linear infinite alternate-reverse;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    border-radius: 6px;
  }

  @keyframes phAnimation {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
`

const initialStyle = css`
  & {
    background: transparent;
  }
`

const LoadingPlaceholder = ({ w = '100%', maxW = '100%', h = '1rem', delay = 0, ...props }) => {
  const [style, setStyle] = useState(initialStyle)

  useEffect(() => {
    if (Number.isInteger(delay)) {
      const timeout = setTimeout(() => {
        setStyle(animatedStyle)
      }, delay)

      return () => clearTimeout(timeout)
    }
  }, [delay])
  return (
    <Box
      className="LoadingPlaceholder"
      position="relative"
      rounded="sm"
      overflow="hidden"
      d="inline-block"
      w={w}
      maxW={maxW}
      h={h}
      css={style}
      data-test="placeholder"
      {...props}
    />
  )
}

LoadingPlaceholder.propTypes = {
  w: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.shape({
      base: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string
    })
  ]),
  maxW: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.shape({
      base: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string
    })
  ]),
  h: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    PropTypes.shape({
      base: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string
    })
  ]),
  delay: PropTypes.number
}

export default LoadingPlaceholder
